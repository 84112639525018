import React from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "@querys/queryFragments" // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta"
import Layout from "@components/layout"
import SimplePortableText from "@components/sanity/simplePortableText"

const simplePageTemplate = ({ data: { page } }) => {
  const { seo, title, text } = page || {}

  return (
    <Layout>
      <div className="bg-sand px-gutter py-space-l">
        <PageMeta {...seo} />
        <h1 className="BlockH1 text-center">{title}</h1>
        <SimplePortableText text={text} className='pt-space-m md:px-gutter' />
      </div>
    </Layout>
  )
}

export default simplePageTemplate

export const simpePageQuery = graphql`
  query standardSimplePageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      text:_rawText(resolveReferences: {maxDepth: 10})
    }
  }
`
